<template>
  <el-card shadow="never" class="aui-card--fill">
    <el-container>
      <el-main>
        <div class="mod-__wbs">
          <el-form :inline="true" :model="dataForm">
            <!-- 项目选择 -->
            <el-form-item>
              <project-select v-model="dataForm.prjId" placeholder="项目选择" :defaultSelect="true" @change="changeProjectHandle"></project-select>
            </el-form-item>

            <el-form-item>
              <el-date-picker style="width: 180px" v-model="dataForm.endDate"
                type="date"
                format="yyyy年MM月dd日"
                value-format="yyyy-MM-dd" placeholder="请选择截止日期"></el-date-picker>
            </el-form-item>

            <!-- 报表选择 -->
            <el-form-item>
              <luckysheet-report-select @change="reportChangeHandle" v-model="dataForm.bindingId" :refId="dataForm.prjId" placeholder="报表选择" :defaultSelect="true" style="width: 98%;"></luckysheet-report-select>
            </el-form-item>

            <!-- 按钮 -->
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="query" v-if="$hasPermission('mps:estReport:preview')" @click="reloadData()"></toolbar-button>
                <toolbar-button name="打印/导出" icon="el-icon-printer" v-if="$hasPermission('mps:estReport:export')" @click="exportHandle()"></toolbar-button>
                <el-tooltip content="重新生成报表需要较长时间，请耐心等待。" placement="top-start" effect="light">
                  <toolbar-button name="重新生成" icon="el-icon-refresh" v-if="$hasPermission('mps:estReport:re-generate')" @click="generateHandle()"></toolbar-button>
                </el-tooltip>
              </el-button-group>
            </el-form-item>
          </el-form>
          <div :style="[divStyle1, divStyle2]">
            <div id="luckysheet" style="margin:0px;padding:0px;position:absolute;width:100%;height:100%;left: 0px;"></div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-card>
</template>
<script>
// 概预算报表
import LuckyExcel from 'luckyexcel'
import LuckysheetReportSelect from "@/views/modules/mps/luckysheet-report-select"
export default {
  data () {
    return {
      dataForm: {
        prjId: '',
        bindingId: '',
        endDate: ''
      },
      divStyle1: {
        width: '100%',
        height: '560px',
        border: '1px solid #ddd',
        position: 'relative'
      },
      divStyle2: {
        display: 'none'
      },
    }
  },
  components: {
    LuckyExcel,
    LuckysheetReportSelect
  },
  methods: {
    changeProjectHandle() {
      // 为了避免每天打开该报表时都重新生成，默认查询已生成报表的最末时间
      this.getLatestEndDate()
    },
    // 切换报表自动加载
    reportChangeHandle() {
      this.reloadData()
    },
    
    // 为了避免每天打开该报表时都重新生成，默认查询已生成报表的最末时间
    async getLatestEndDate() {
      this.dataForm.endDate = ''
      await this.$http.get(`/mps/estReport/latestEndDate/${this.dataForm.prjId}`).then(({data: res}) => {
        if (res.code === 0) {
          this.dataForm.endDate = res.data
        }
      }).catch()
    },
    // 点击“查询”按钮
    queryData() {
      if (!this.dataForm.endDate) {
        return this.$message.error('请选择截止日期！')
      }
      if (!this.dataForm.bindingId) {
        return this.$message.error('请选择报表！')
      }
      this.reloadData()
    },
    // 加载具体的报表
    reloadData() {
      if (!this.dataForm.bindingId || !this.dataForm.endDate) {
        return
      }
      this.$http.get(`/mps/estReport`, {
        params: {
          prjId: this.dataForm.prjId,
          bindingId: this.dataForm.bindingId,
          //endDate: this.formatEndDate(),
          endDate: this.dataForm.endDate,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          window.luckysheet.destroy()
          return this.$confirm('未查询到该报表，是否需要立即生成？', '警告', {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            this.doGenerate()
          }).catch(e => e)
        }
        let title = res.data.name
        if (!res.data.excelUrl) {
          window.luckysheet.destroy()
          this.$message({
            message: '未查询到该报表，请重新生成！',
            type: 'error',
            duration: 3000,
            onClose: () => {
              // do nothing
            }
          })
          return false
        }
        let _this = this
        _this.luckysheet_preview_loading = this.$loading({
          text: '正在加载报表数据...',
          spinner: 'el-icon-loading',
          body: true,
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        // 打开signedUrl
        this.$http.get(`/oss/file/signedUrl?url=${res.data.excelUrl}`).then(({ data: res2 }) => {
          if (res2.code === 0) {
            this.initLuckysheet(res2.data, title)
          }
        }).catch(() => {
          _this.luckysheet_preview_loading.close()
        })
      }).catch(() => {
        // do nothing
      })
    },
    initLuckysheet(url, title) {
      this.divStyle2.display ='none'
      this.divStyle1.height = `${(document.documentElement.clientHeight || document.body.clientHeight) - 200}px`
      this.title = title
      let _this = this
      // LuckyExcel中请求时如果出现问题将会导致loading一直不消失，暂时通过定时器解决，超过60秒关闭loading
      let stopLoadingTimeout = window.setTimeout(()=>{
        _this.luckysheet_preview_loading.close()
      }, 60000)
      
      this.$nextTick(() => {
        $(function () {
          LuckyExcel.transformExcelToLuckyByUrl(url, title, function (exportJson, luckysheetfile) {
            
            // 循环遍历json，强制所有单元格溢出后自动换行
            _this.setTextWrapped(exportJson.sheets)

            window.clearTimeout(stopLoadingTimeout)
            if (exportJson.sheets == null || exportJson.sheets.length == 0) {
              _this.luckysheet_preview_loading.close()
              _this.divStyle2.display ='block'
              return
            }
            _this.divStyle2.display ='block'
            _this.luckysheet_preview_loading.close()
            window.luckysheet.destroy()

            let jsonData = exportJson.sheets
            if (jsonData && jsonData.length > 0) {
              // 隐藏网格线
              jsonData[0].showGridLines = 0
            }

            window.luckysheet.create({
              container: 'luckysheet',
              allowCopy: true, // 是否允许拷贝
              showtoolbar: false, // 是否显示工具栏
              showinfobar: false, // 是否显示顶部信息栏
              showstatisticBar: false, // 是否显示底部计数栏
              sheetBottomConfig: false, // sheet页下方的添加行按钮和回到顶部按钮配置
              allowEdit: false, // 是否允许前台编辑
              enableAddRow: false, // 允许增加行
              enableAddCol: false, // 允许增加列
              userInfo: false, // 右上角的用户信息展示样式
              showRowBar: false, // 是否显示行号区域
              showColumnBar: false, // 是否显示列号区域
              sheetFormulaBar: false, // 是否显示公式栏
              enableAddBackTop: false,// 返回头部按钮
              data: jsonData,
              title: title,
              lang: 'zh',
            })
          })
        })
      })
    },
    // 循环遍历json，强制所有单元格溢出后自动换行
    setTextWrapped(sheets) {
      sheets && sheets.forEach(sheet=>{
        let celldatas = sheet['celldata']
        if (celldatas && celldatas.length > 0) {
          celldatas.forEach(obj=>{
            // 0：截断、1：溢出、2：自动换行
            if (obj['v']['tb']) {
              // 强制换行
              obj['v']['tb'] = 2
            }
          })
        }
        
        let datas = sheet['data']
        if (datas && datas.length > 0) {
          datas.forEach(arr=>{
            if(arr && arr.length > 0) {
              arr.forEach(obj=>{
                // 0：截断、1：溢出、2：自动换行
                if (obj['tb']) {
                  // 强制换行
                  obj['tb'] = 2
                }
              })
            }
          })
        }
      })
    },
    // 生成报表实例（包括Excel、Pdf文件）
    generateHandle() {
      this.$confirm('报表生成或需较长时间，请耐心等待。', this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.doGenerate()
      }).catch(e => e)
    },
    doGenerate() {
      let _this = this
      const loading = this.$loading({
        text: '报表文件正在生成，请不要关闭当前页面...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post(`/mps/estReport/generate`, {
        prjId: this.dataForm.prjId,
        bindingId: this.dataForm.bindingId,
        endDate: this.dataForm.endDate,
      }).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        loading.close()
        this.$message({
          message: '报表已生成，正在显示...',
          type: 'success',
          duration: 10000,
        })
        // 生成完毕自动加载打开在线预览
        _this.reloadData()
      }).catch((e) => {
        loading.close()
      })
    },
    // 导出（即：打开Pdf文件）
    exportHandle() {
      const loading = this.$loading({
        text: '正在加载...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$http.get(`/mps/estReport`, {
        params: {
          prjId: this.dataForm.prjId,
          bindingId: this.dataForm.bindingId,
          endDate: this.dataForm.endDate,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        // 打开signedUrl
        this.$http.get(`/oss/file/signedUrl?url=${res.data.pdfUrl}`).then(({ data: res }) => {
          if (res.code === 0) {
            window.open(res.data)
          }
          loading.close()
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {
        loading.close()
      })
    },
    getLastDayOfThisMonth() {
      var my_date = new Date()
      return new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
    },
    // 作废
    formatEndDate() {
      if (this.dataForm.endDate) {
        let date = new Date(this.dataForm.endDate)
        var y = date.getFullYear()
        var m = date.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        var d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        return y + '-' + m + '-' + d
      } else {
        return ''
      }
    },
  }
}
</script>
<style>
  .luckysheet-cols-menu {z-index: 9004;}
  .luckysheet-input-box {z-index: 3000}
  .rlit {
    font-size: 14px; padding-bottom: 2px;
  }
</style>