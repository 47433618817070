<template>
  <el-card shadow="never" class="aui-card--fill">
    <el-container>
      <el-main>
        <div class="mod-__wbs">
          <el-form :inline="true" :model="dataForm">
            <!-- 项目选择 -->
            <el-form-item>
              <project-select v-model="dataForm.prjId" placeholder="项目选择" :defaultSelect="true"></project-select>
            </el-form-item>
            <!-- 标段选择 -->
            <el-form-item>
              <contract-select v-model="dataForm.contractId" :prj-id="dataForm.prjId" placeholder="标段选择" @change="contractChangeHandle" :defaultSelect="true" style="width: 93%;"></contract-select>
            </el-form-item>
            <!-- 期次选择 -->
            <el-form-item>
              <period-select v-model="dataForm.periodId" :contractId="dataForm.contractId" placeholder="期次选择" has-meas default-select :hasMeas="true"></period-select>
            </el-form-item>
            <!-- 工区选择 -->
            <el-form-item v-if="hasSubcontractor">
              <subcontractor-select :appendAllOption="true" v-model="dataForm.subcontractorId" :contract-id="dataForm.contractId" placeholder="工区选择" :defaultSelect="true"></subcontractor-select>
            </el-form-item>
            <!-- 报表选择 -->
            <el-form-item>
              <luckysheet-report-select v-model="dataForm.bindingId" :refId="dataForm.contractId" placeholder="报表选择" :defaultSelect="true" style="width: 93%;"></luckysheet-report-select>
            </el-form-item>
            <!-- 按钮组 -->
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="query" v-if="$hasPermission('mps:measreport:preview')" @click="reloadData()"></toolbar-button>
                <toolbar-button role="export" v-if="$hasPermission('mps:measreport:export')" @click="exportHandle()"></toolbar-button>
                <el-tooltip content="重新生成报表需要较长时间，请耐心等待。" placement="top-start" effect="light">
                  <toolbar-button name="重新生成" icon="el-icon-refresh" v-if="$hasPermission('mps:measreport:generate')" @click="generateHandle()"></toolbar-button>
                </el-tooltip>
              </el-button-group>
            </el-form-item>
          </el-form>
          <vue-office-excel :src="excelUrl" @rendered="renderingCompleted"/>
        </div>
      </el-main>
    </el-container>
  </el-card>
</template>
<script>
import VueOfficeExcel from '@vue-office/excel'
import LuckysheetReportSelect from "@/views/modules/mps/luckysheet-report-select"
export default {
  data () {
    return {
      dataForm: {
        // 项目ID
        prjId: '',
        // 标段ID
        contractId: '',
        // 工区ID
        subcontractorId: '',
        // 期次ID
        periodId: '',
        // 报表ID
        bindingId: ''
      },
      divStyle1: {
        width: '100%',
        height: '560px',
        border: '1px solid #ddd',
        position: 'relative'
      },
      divStyle2: {
        display: 'none'
      },
      hasSubcontractor: false,
      excelUrl: '',
    }
  },
  components: {
    LuckysheetReportSelect,
    VueOfficeExcel
  },
  methods: {
    contractChangeHandle(data) {
      // 如果该标段有工区，则显示工区选择框
      // **若data为null，说明此用户没有任何标段权限，此时设为有工区，避免错误的查询出数据
      // 否则将工区id设为undefined，避免后台查询时以其为参数
      if (!data || data.hasSubcontractor == '1') {
        this.hasSubcontractor = true
      } else {
        this.hasSubcontractor = false
        this.dataForm.subcontractorId = undefined
      }
    },
    renderingCompleted() {
      console.log("渲染完成");
    },
    // 在线加载显示报表
    reloadData() {
      if (!this.dataForm.periodId) {
        return this.$message.error('请选择期次！')
      }
      if (!this.dataForm.bindingId) {
        return this.$message.error('请选择报表！')
      }
      // 工区（subcontractorId）允许为空（工区为空时，查询或生成的报表是按标段维度的）
      this.$http.get(`/mps/measReport`, {
        params: {
          periodId: this.dataForm.periodId,
          bindingId: this.dataForm.bindingId,
          subcontractorId: this.dataForm.subcontractorId,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          this.$confirm('未查询到该报表，是否需要立即生成？', '警告', {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            this.doGenerate()
          }).catch(e => e)
          return false
        }
        if (!res.data.excelUrl) {
          this.$message({
            message: '未查询到该报表，请重新生成！',
            type: 'error',
            duration: 3000,
            onClose: () => {
              // do nothing
            }
          })
          return false
        }
        // 打开signedUrl
        this.$http.get(`/oss/file/signedUrl?url=${res.data.excelUrl}`).then(({ data: res2 }) => {
          if (res2.code === 0) {
            this.excelUrl = res2.data
          }
        }).catch(() => {
        })
      }).catch(() => {
        // do nothing
      })
    },
    // 生成报表实例（包括Excel、Pdf文件）
    generateHandle() {
      this.$confirm('报表生成或需较长时间，请耐心等待。', this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.doGenerate()
      }).catch(e => e)
    },
    doGenerate() {
      let _this = this
      const loading = this.$loading({
        text: '报表文件正在生成，请不要关闭当前页面...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post(`/mps/measReport/generate`, {
        periodId: this.dataForm.periodId,
        bindingId: this.dataForm.bindingId,
        subcontractorId: this.dataForm.subcontractorId,
      }).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        loading.close()
        this.$message({
          message: '报表已生成，正在显示...',
          type: 'success',
          duration: 10000,
          onClose: () => {
            // do nothing
          }
        })
        // 生成完毕自动加载打开在线预览
        _this.reloadData()
      }).catch((e) => {
        loading.close()
      })
    },
    // 导出（即：打开Pdf文件）
    exportHandle() {
      const loading = this.$loading({
        text: '正在加载...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$http.get(`/mps/measReport`, {
        params: {
          periodId: this.dataForm.periodId,
          bindingId: this.dataForm.bindingId,
          subcontractorId: this.dataForm.subcontractorId,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        // 打开signedUrl
        this.$http.get(`/oss/file/signedUrl?url=${res.data.pdfUrl}`).then(({ data: res }) => {
          if (res.code === 0) {
            window.open(res.data)
          }
          loading.close()
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {
        loading.close()
      })
    }
  }
}
</script>
