<template>
  <el-card shadow="never" class="aui-card--fill">
    <el-container>
      <el-main>
        <div class="mod-__wbs">
          <el-form :inline="true" :model="dataForm">
            <!-- 项目选择 -->
            <el-form-item>
              <project-select v-model="dataForm.prjId" placeholder="项目选择" :defaultSelect="true"></project-select>
            </el-form-item>
            <!-- 标段选择 -->
            <el-form-item>
              <contract-select v-model="dataForm.contractId" :prjId="dataForm.prjId" placeholder="标段选择" @change="contractChangeHandle" :defaultSelect="true" style="width: 250px;"></contract-select>
            </el-form-item>
            <!-- 期次选择 -->
            <el-form-item>
              <period-select v-model="dataForm.periodId" :contractId="dataForm.contractId" placeholder="期次选择" @change="periodChangeHandle" :defaultSelect="true" :hasMeas="true" style="width: 120px;"></period-select>
            </el-form-item>
            <!-- 工区选择 -->
            <!-- <el-form-item v-if="hasSubcontractor">
              <subcontractor-select :appendAllOption="true" v-model="dataForm.subcontractorId" :contract-id="dataForm.contractId" placeholder="工区选择" :defaultSelect="true"></subcontractor-select>
            </el-form-item> -->
            <!-- 报表选择 -->
            <el-form-item>
              <luckysheet-report-select v-model="dataForm.bindingId" :refId="dataForm.contractId" placeholder="报表选择" @change="reportChangeHandle" :defaultSelect="true" style="width: 230px;"></luckysheet-report-select>
            </el-form-item>
            <!-- 按钮 -->
            <el-form-item>
              <el-button-group style="margin-top: -3px;">
                <toolbar-button role="query" v-if="$hasPermission('mps:measreport:preview')" @click="reloadData()"></toolbar-button>
                <toolbar-button name="打印/导出" icon="el-icon-printer" v-if="$hasPermission('mps:measreport:export')" @click="exportHandle()"></toolbar-button>
                <el-tooltip content="重新生成报表需要较长时间，请耐心等待。" placement="top-start" effect="light">
                  <toolbar-button name="重新生成" icon="el-icon-refresh" v-if="$hasPermission('mps:measreport:re-generate')" @click="generateHandle()"></toolbar-button>
                </el-tooltip>
              </el-button-group>
            </el-form-item>
          </el-form>
          <div :style="[divStyle1, divStyle2]">
            <div id="luckysheet" style="margin:0px;padding:0px;position:absolute;width:100%;height:100%;left: 0px;"></div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-card>
</template>
<script>
// 计量报表
import LuckyExcel from 'luckyexcel'
import LuckysheetReportSelect from "@/views/modules/mps/luckysheet-report-select"
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      dataForm: {
        // 项目ID
        prjId: '',
        // 标段ID
        contractId: '',
        // 目前计量报表不支持按工区查询，如果需要，建议专门开发一个菜单用于工区查询自己的报表
        subcontractorId: '',
        // 期次ID
        periodId: '',
        // 报表ID
        bindingId: ''
      },
      divStyle1: {
        width: '100%',
        height: '560px',
        border: '1px solid #ddd',
        position: 'relative'
      },
      divStyle2: {
        display: 'none'
      },
      //hasSubcontractor: false
    }
  },
  components: {
    LuckyExcel,
    LuckysheetReportSelect
  },
  methods: {
    contractChangeHandle(data) {
      // 如果该标段有工区，则显示工区选择框
      // 若data为null，说明此用户没有任何标段权限，此时设为有工区，避免错误的查询出数据，否则将工区id设为undefined，避免后台查询时以其为参数
      //if (!data || data.hasSubcontractor == '1') {
        //this.hasSubcontractor = true
      //} else {
        //this.hasSubcontractor = false
        //this.dataForm.subcontractorId = undefined
      //}
    },
    // 期次切换
    periodChangeHandle() {
      if (this.dataForm.bindingId) {
        this.reloadData()
      }
    },
    // 切换报表
    reportChangeHandle() {
      if (this.dataForm.periodId) {
        this.reloadData()
      }
    },
    reloadData: debounce(function () {
      if (!this.dataForm.periodId) {
        return this.$message.error('请选择期次！')
      }
      if (!this.dataForm.bindingId) {
        return this.$message.error('请选择报表！')
      }
      const loading = this.$loading({
        text: '正在加载报表...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      // 工区（subcontractorId）允许为空（工区为空时，查询或生成的报表是按标段维度的）
      this.$http.get(`/mps/measReport`, {
        params: {
          periodId: this.dataForm.periodId,
          bindingId: this.dataForm.bindingId,
          subcontractorId: this.dataForm.subcontractorId,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          window.luckysheet.destroy()
          return this.$confirm('未查询到该报表，是否需要立即生成？', '警告', {
            confirmButtonText: this.$t('confirm'),
            cancelButtonText: this.$t('cancel'),
            type: 'warning'
          }).then(() => {
            this.doGenerate()
          })
        }
        let title = res.data.name
        if (!res.data.excelUrl) {
          window.luckysheet.destroy()
          loading.close()
          return this.$message({
            message: '未查询到该报表，请重新生成！',
            type: 'error',
            duration: 2000,
          })
        }
        let _this = this
        _this.luckysheet_preview_loading = this.$loading({
          text: '正在加载报表数据...',
          spinner: 'el-icon-loading',
          body: true,
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        // 打开signedUrl
        this.$http.get(`/oss/file/signedUrl?url=${res.data.excelUrl}`).then(({ data: res2 }) => {
          if (res2.code === 0) {
            let signedUrl = res2.data
            if (signedUrl && signedUrl.length > 0) {
              this.initLuckysheet(signedUrl, title)
            } else {
              this.$message({
                message: '未查询到该报表，请重新生成！',
                type: 'error',
                duration: 2000,
              })
            }
          }
        })
      }).finally(() => {
        loading.close()
      })
    }, 1000, {'leading': true, 'trailing': false}),
    initLuckysheet(url, title) {
      this.divStyle2.display ='none'
      this.divStyle1.height = `${(document.documentElement.clientHeight || document.body.clientHeight) - 200}px`
      this.title = title
      let _this = this
      // LuckyExcel中请求时如果出现问题将会导致loading一直不消失，暂时通过定时器解决，超过60秒关闭loading
      let stopLoadingTimeout = window.setTimeout(()=>{
        _this.luckysheet_preview_loading.close()
      }, 60000)
      
      this.$nextTick(() => {
        $(function () {
          LuckyExcel.transformExcelToLuckyByUrl(url, title, function (exportJson, luckysheetfile) {
            // 循环遍历json，强制所有单元格溢出后自动换行
            _this.setTextWrapped(exportJson.sheets)

            window.clearTimeout(stopLoadingTimeout)
            if (exportJson.sheets == null || exportJson.sheets.length == 0) {
              _this.luckysheet_preview_loading.close()
              _this.divStyle2.display ='block'
              return
            }
            _this.divStyle2.display ='block'
            _this.luckysheet_preview_loading.close()
            window.luckysheet.destroy()

            let jsonData = exportJson.sheets
            if (jsonData && jsonData.length > 0) {
              // 隐藏网格线
              jsonData[0].showGridLines = 0
            }

            window.luckysheet.create({
              container: 'luckysheet',
              allowCopy: true, // 是否允许拷贝
              showtoolbar: false, // 是否显示工具栏
              showinfobar: false, // 是否显示顶部信息栏
              showstatisticBar: false, // 是否显示底部计数栏
              sheetBottomConfig: false, // sheet页下方的添加行按钮和回到顶部按钮配置
              allowEdit: false, // 是否允许前台编辑
              enableAddRow: false, // 允许增加行
              enableAddCol: false, // 允许增加列
              userInfo: false, // 右上角的用户信息展示样式
              showRowBar: false, // 是否显示行号区域
              showColumnBar: false, // 是否显示列号区域
              sheetFormulaBar: false, // 是否显示公式栏
              enableAddBackTop: false,// 返回头部按钮
              data: jsonData,
              title: title,
              lang: 'zh',
            })
          })
        })
      })
    },
    // 循环遍历json，强制所有单元格溢出后自动换行
    setTextWrapped(sheets) {
      sheets && sheets.forEach(sheet=>{
        let celldatas = sheet['celldata']
        if (celldatas && celldatas.length > 0) {
          celldatas.forEach(obj=>{
            // 0：截断、1：溢出、2：自动换行
            if (obj['v']['tb']) {
              // 强制换行
              obj['v']['tb'] = 2
            }
          })
        }
        
        let datas = sheet['data']
        if (datas && datas.length > 0) {
          datas.forEach(arr=>{
            if(arr && arr.length > 0) {
              arr.forEach(obj=>{
                // 0：截断、1：溢出、2：自动换行
                if (obj['tb']) {
                  // 强制换行
                  obj['tb'] = 2
                }
              })
            }
          })
        }
      })
    },
    // 生成报表实例（包括Excel、Pdf文件）
    generateHandle() {
      this.$confirm('生成报表需较长时间，请耐心等待。', this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.doGenerate()
      }).catch(e => e)
    },
    doGenerate() {
      let _this = this
      const loading = this.$loading({
        text: '报表正在生成，请稍等片刻...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http.post(`/mps/measReport/generate`, {
        periodId: this.dataForm.periodId,
        bindingId: this.dataForm.bindingId,
        subcontractorId: this.dataForm.subcontractorId,
      }).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: '报表已生成，正在显示...',
          type: 'success',
          duration: 3000,
        })
        // 生成完毕自动加载打开在线预览
        _this.reloadData()
      }).finally(() => {
        loading.close()
      })
    },
    // 导出（即：打开Pdf文件）
    exportHandle() {
      const loading = this.$loading({
        text: '正在加载...',
        spinner: 'el-icon-loading',
        customClass: 'my-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$http.get(`/mps/measReport`, {
        params: {
          periodId: this.dataForm.periodId,
          bindingId: this.dataForm.bindingId,
          subcontractorId: this.dataForm.subcontractorId,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          loading.close()
          return this.$message.error(res.msg)
        }
        // 打开signedUrl
        this.$http.get(`/oss/file/signedUrl?url=${res.data.pdfUrl}`).then(({ data: res }) => {
          if (res.code === 0) {
            window.open(res.data)
          }
          loading.close()
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {
        loading.close()
      })
    }
  }
}
</script>
<style>
  .luckysheet-cols-menu {z-index: 9004;}
  .luckysheet-input-box {z-index: 3000}
  .rlit {
    font-size: 14px; padding-bottom: 2px;
  }
  /* 隐藏点击图片时在右侧打开的图片属性窗口 */
  .luckysheet-modal-dialog-slider{
    display: none !important;
  }
</style>