<template>
  <el-tooltip placement="bottom-start" :disabled="tooltipDisabled" :content="tooltip" effect="light" :enterable="enterable" :open-delay="1000">
    <el-select v-model="selectedId" :placeholder="(!placeholder && disabled) ? '' : placeholder" :disabled="disabled" filterable :clearable="clearable">
      <el-option v-for="opt in dataList" :label="opt.name" :key="opt.id" :value="opt.id"></el-option>
    </el-select>
  </el-tooltip>
</template>
<script>
// 报表下拉框（基于luckysheet的整套报表体系）
export default {
  data () {
    return {
      dataList: [],
      tooltip: '请选择',
      enterable: false,
      tooltipDisabled: false,
      selectedId: this.value
    }
  },
  created: function () {
    this.getDataList()
  },
  watch: {
    refId: function() {
      this.dataList = []
      this.selectedId = ''
      if (this.refId) {
        this.getDataList()
      }
    },
    // value改变时，修改selectedId和tooltip的值
    value: function (data) {
      let item = this.getItemById(data)
      this.tooltip = item ? item.name : '请选择'
      this.selectedId = data
    },
    selectedId: function(data) {
      // 触发input事件完成v-model的双向绑定
      this.$emit('input', data)
      this.$emit('change', this.getItemById(data))
    }
  },
  props: {
    value: String,
    refId: String,
    placeholder: String,
    disabled: Boolean,
    clearable: Boolean,
    // 是否默认选中第一个
    defaultSelect: false
  },
  methods: {
    getItemById (id) {
      let item = this.dataList.find((item) => {
        return item.id === id
      })
      if (item) {
        this.tooltipDisabled = false
        return item
      } else {
        if (this.disabled) {
          this.tooltipDisabled = true
        }
        return null
      }
    },
    getDataList() {
      this.$http.get('/mps/reportBinding/briefs', {
        params: {
          refId: this.refId
        }
      }).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        // 该组件支持是否默认选中第一个选项（可通过defaultSelect属性控制）
        if (this.defaultSelect && this.dataList.length > 0) {
          // 控制el-select选中
          this.selectedId = this.dataList[0].id
        }
      }).catch()
    }
  }
}
</script>
